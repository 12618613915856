import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import getLanguage from '../utils/getLanguage';

export default function About() {
  const language = getLanguage();

  return (
    <Layout page="about">
      <SEO title="About" />
      <div className="textContent">
        <h2>About</h2>
        <h3>Ben</h3>
        <div className="row">
          <div className="span2">
            <img className="aboutImage" src="/img/about/bride2_150px.jpg" />
          </div>
          <div className="span6">
            {language === 'en' ? (
              <>
                <p>
                  <b>Benjawan Chanthaworakit (Ben)</b>&nbsp; received a Bachelor Degree in
                  Pharmaceutical Science from Chulalongkorn University in Bangkok, Thailand, which
                  was where she met Krist.
                </p>
                <p>
                  She is now a medical representative at Merck Sharp and Dohme (MSD) in Bangkok. In
                  addition, Ben recently received an MBA degree from Chulalongkorn University.
                </p>
                <p>Pink is her favorite color, which led to the color theme of this wedding.</p>
              </>
            ) : (
              <>
                <p>
                  <b>เบญจวรรณ ฉันทวรกิจ (เบญ)</b>&nbsp; จบการศึกษาจากโรงเรียนเขมะสิริอนุสสรณ์
                  และปริญญาตรีจากคณะเภสัชศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย
                </p>
                <p>
                  ปัจจุบันเบญทำงานเป็นผู้แทนยาที่บริษัท Merck Sharp and Dohme (MSD)
                  และเพิ่งจบปริญญาโทสาขาบริหารธุรกิจจากจุฬาลงกรณ์มหาวิทยาลัยมาอีกใบสดๆร้อนๆ
                </p>
                <p>สีโปรดของเบญคือสีชมพู ซึ่งเป็นที่มาของ theme งานนี้</p>
              </>
            )}
          </div>
        </div>
        <h3>Muu+</h3>
        <div className="row">
          <div className="span2">
            <img className="aboutImage" src="/img/about/groom2_150px.jpg" />
          </div>
          <div className="span6">
            {language === 'en' ? (
              <>
                <p>
                  <b>
                    <a href="http://kristw.yellowpigz.com">Krist Wongsuphasawat (Muu)</a>
                  </b>
                  &nbsp; received a Bachelor Degree in Computer Engineering from Chulalongkorn
                  University in Bangkok, Thailand. Muu is his nickname, meaning "a pig" in Thai.
                </p>
                <p>
                  After graduation, he went to the U.S. to continue his study at the University of
                  Maryland, College Park and received his Master and PhD degrees in 2009 and 2012,
                  respectively.
                </p>
                <p>
                  He is now a Data Visualization Scientist at Twitter
                  <i className="twitter" />
                  in San Francisco. If you don't have a twitter account yet, get one. (Yes, I mean
                  you!) He made this website to make his bride happy and hope he can give some
                  smiles to the visitors.
                </p>
              </>
            ) : (
              <>
                <p>
                  <b>
                    <a href="http://kristw.yellowpigz.com">กฤษฏิ์ วงศ์ศุภสวัสดิ์ (หมู)</a>
                  </b>
                  &nbsp; จบการศึกษาจากโรงเรียนอัสสัมชัญ และปริญญาตรีจากคณะวิศวกรรมศาสตร์
                  สาขาวิศวกรรมคอมพิวเตอร์ จุฬาลงกรณ์มหาวิทยาลัย
                </p>
                <p>
                  หลังจากจบปริญญาตรีหมูก็ไปต่อปริญญาโทและเอก สาขา Computer Science ที่ University of
                  Maryland, College Park ประเทศสหรัฐอเมริกา
                </p>
                <p>
                  ปัจจุบันหมูทำงานเป็น Data Visualization Scientist ที่บริษัท Twitter&nbsp;
                  <i className="twitter" />
                  &nbsp;( ใครไม่รู้จักก็สมัครซะนะ ...
                  <a href="http://www.twitter.com">www.twitter.com</a>) อยู่ที่เมือง San Francisco
                  ประเทศสหรัฐอเมริกา ตอนนี้พยายามทำตัวมีประโยชน์ด้วยการนั่งทำเว็บนี้ขึ้นมา
                </p>
              </>
            )}
          </div>
        </div>
        <p>&nbsp;</p>
      </div>
    </Layout>
  );
}
